@font-face {
    font-family: "FranklinGothicATF-Heavy";
    src: url("../font/FranklinGothicATF-Heavy.otf")
}
@font-face {
    font-family: "Myriad-Normal.otf";
    src: url("../font/Myriad-Normal.otf")
}
html,body,{
    font-family: "Myriad-Normal.otf",-apple-system;
    &.hidden{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //width: calc(100% - 15px);
        height: 100%;
        //.nav{
        //    left: -7.5px;
        //}
        .m-container{
            filter: blur(2px);
        }
    }
}
div,p,h1,h2,h3{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul,li{
    list-style: none;
    padding: 0;
    margin: 0;
}
a,a:hover,a:active,a:visited,a:link,a:focus{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    outline:none;
    background: none;
    text-decoration: none;
}
a{
    color: inherit;
    text-decoration: none;
}
img{
    user-select: none;
}
