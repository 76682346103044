
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes bottomIn {
    0% {}
    100% {
        transform: translateY(-50%);
    }
}
