body{
    --d-white: #fff;
    --d-black: #121212;
    --d-bg-1: #F9EEE3;
    --d-blue: #847AFF;
    --d-orange: #FFA143;
    --d-fz-90: 90px;
    --d-fz-60: 60px;
    --d-fz-40: 40px;
    --d-fz-30: 30px;
    --d-fz-24: 24px;
    --d-fz-18: 18px;
    --d-fz-16: 16px;
    --d-fz-14: 14px;
}
