.container{
    margin: 0 auto;
    padding: 0 78px 0 150px;
    max-width: 1280px;
    min-width: 1024px;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.lineH{
    &_0{
        line-height: 0;
    }
}
.fw400{
    font-weight: 400;
}
.fz16{
    font-size: 16px;
}
.fz18{
    font-size: 18px;
}
.color-orange{
    color: #FFA143;
}
.txt-underline{
    text-decoration: underline;
}
.mt10{
    margin-top: 10px;
}
.ml10{
    margin-left: 10px;
}
.mb20{
    margin-bottom: 20px;
}
.mt20{
    margin-top: 20px;
}
.mr20{
    margin-right: 20px;
}
.mt30{
    margin-top: 30px;
}
.mb30{
    margin-bottom: 30px;
}
.mb40{
    margin-bottom: 40px;
}
.mb50{
    margin-bottom: 50px;
}
.mt60{
    margin-top: 60px;
}
.mb60{
    margin-bottom: 60px;
}
.mb120{
    margin-bottom: 120px;
}
.mt120{
    margin-top: 120px;
}
.pt120{
    padding-top: 120px;
}
.pt148{
    padding-top: 148px;
}
.pt200{
    padding-top: 200px;
}
.pl-70{
    padding-left: 70px;
}
.pr-70{
    padding-right: 70px;
}
.relative{
    position: relative;
}
.absolute {
    position: absolute;
}
.h-full {
    min-height: 100vh;
}
.left-1\/2 {
    left: 50%;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.pointer{
    cursor: pointer;
}
.flex{
    display: flex;
    &-item{
        flex: 1;
    }
    &-center{
        &_hor{
            align-items: center;
        }
        &_ver{
            justify-content: center;
        }
    }
    &-btw{
        justify-content: space-between;
    }
    &-wrap{
        flex-wrap: wrap;
    }
    &-end{
        justify-content: flex-end;
    }
}
.grid{
    display: grid;
}
.gap-4{
    gap: 4px;
}
.gap-8{
    gap: 8px;
}
.gap-18{
    gap: 18px;
}
.gap-30{
    gap: 30px;
}
.grid-col-9{
    grid-template-columns: repeat(9,minmax(0,1fr));
}
.grid-col-11{
    grid-template-columns: repeat(11,minmax(0,1fr));
}
.grid-col-10{
    grid-template-columns: repeat(10,minmax(0,1fr));
}
.grid-col-12{
    grid-template-columns: repeat(12,minmax(0,1fr));
}

.col-span-2{
    grid-column: span 2/span 2
}
.col-span-3{
    grid-column: span 3/span 3
}
.col-span-4{
    grid-column: span 4/span 4
}
.col-span-5{
    grid-column: span 5/span 5
}
.col-span-6{
    grid-column: span 6/span 6
}
.col-span-7{
    grid-column: span 7/span 7
}
.col-span-8{
    grid-column: span 8/span 8
}
.col-span-9{
    grid-column: span 9/span 9
}
.col-span-10{
    grid-column: span 10/span 10
}
.col-span-11{
    grid-column: span 11/span 11
}
.col-span-12{
    grid-column: span 12/span 12
}

.sm-show{
    display: none;
}

@media screen and (max-width: 640px) {
    .container{
        min-width: 300px;
    }
    .sm-hide{
        display: none;
    }
    .sm-show{
        display: block;
    }
    .ml10{
        margin-left: 5px;
    }
    .mt10{
        margin-top: 5px;
    }
    .mt20{
        margin-top: 10px;
    }
    .mb20{
        margin-bottom: 10px;
    }
    .mt30{
        margin-top: 15px;
    }
    .mb30{
        margin-bottom: 15px;
    }
    .mb40{
        margin-bottom: 20px;
    }
    .mb50{
        margin-bottom: 25px;
    }
    .mb60{
        margin-bottom: 30px;
    }
    .mt60{
        margin-top: 30px;
    }
    .mb120{
        margin-bottom: 60px;
    }
    .gap-18{
        gap: 9px;
    }
}
