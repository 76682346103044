@import "reset";
@import "variable";
@import "layout";
@import "animation";
@import "icon";

#root {
    min-width: 1280px;
}

.iconfont {
    line-height: 1;

    & + .iconfont {
        margin-left: 15px;
    }
}

.cta-wallet {
    position: absolute;
    right: 77px;
    top: 11px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 2rem;
    border: 2px solid var(--d-bg-1);
    margin: 0;
    width: auto;
    overflow: visible;
    background: 0 0;
    color: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
    z-index: 100;
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1280px;
    padding: 14px 0;
    font-size: 20px;
    text-align: right;
    background: #fff;
    //box-shadow: 0 0.5px 6px rgba(0, 0, 0, 0.24);
    z-index: 99;

    li {
        display: inline-block;
        vertical-align: middle;

        & + li {
            margin-left: 16px;
        }
    }

    a,
    a:hover,
    a:active,
    a:link,
    a:visited {
        color: inherit;
        text-decoration: none;
    }

    a {
        opacity: 0.9;
        transform-origin: center;
        //transition: all 0.3s ease;
        &.icon {
            &:hover {

                transform: scale(1.1);
            }
        }

        &.page-scroll {
            padding: 6px 14px;
            font-size: 16px;
            border-radius: 8px;
            //transition: background-color 0.3s linear;
            &.active {
                opacity: 1;
                background-color: var(--d-bg-1);
            }
        }
    }

    .navbar-nav {
        position: relative;
        width: 1280px;
        padding-right: 212px;
    }

    .logo-wrap {
        display: flex;
        align-items: center;
        height: 60px;
        line-height: 1;
        font-size: 0;
        background-color: #fff;
        //border-radius: 8px;
        //box-shadow: 0 0.5px 6px rgba(0, 0, 0, 0.24);
        img {
            max-height: 100%;

            &.logo2 {
                max-height: 34px;
            }
        }
    }
}

.msg{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    &-mask{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(216, 216, 216, 0.5);
    }
    &-content{
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 375px;
        padding: 58px 88px;
        text-align: center;
        background-color: #fff;
        transform: translate(-50%,-50%);
        border-radius: 9px;
        transition: opacity 0.3s ease;
        z-index: 2;
        &.out{
            opacity: 0;
        }
    }
    &-close{
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transform: translate(50%,-50%);
        cursor: pointer;
        background: url("../img/icon/close.png");
        background-size: cover;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    &-icon{
        margin: 0 auto;
        width: 150px;
        height: 150px;
        line-height: 0;
        border-radius: 50%;
        &.success{
            background: url("../img/icon/success.png");
            background-size: cover;
        }
        &.error{
            background: url("../img/icon/error.png");
            background-size: cover;
        }
    }
    &-title{
        margin-top: 26px;
        font-size: 30px;
        font-family: "FranklinGothicATF-Heavy";
        font-weight: 800;
        color: var(--d-black);
        letter-spacing: 0.4px;
        line-height: 37px;
    }
    &-summary{
        margin-top: 26px;
        font-size: 20px;
        font-weight: 400;
        color: var(--d-black);
        line-height: 24px;
        letter-spacing: 0.27px;
    }
}
.main-wrap {
    //padding-top: 88px;

    .p1-title {
        font-family: "FranklinGothicATF-Heavy";
        font-size: var(--d-fz-60);
        line-height: 40px;
        letter-spacing: 1.15px;

        &-summary {
            font-family: "FranklinGothicATF-Heavy";
            margin-top: 30px;
            margin-bottom: 50px;
            font-size: var(--d-fz-30);
            color: var(--d-black);
            line-height: 40px;
            letter-spacing: 1.15px;
        }
    }

    .p1-right {
        padding: 17px 25px 18px 24px;
        width: 464px;
        height: 116px;
        background: linear-gradient(180deg, #FFFAE6 0%, #FFC066 100%);
        border-radius: 10px;
        letter-spacing: 1.15px;

        &-icon {
            position: relative;
            padding-right: 23px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 8px;
                right: 0;
                width: 3px;
                height: 52px;
                background: #121212;
            }

            img {
                width: 64px;
            }
        }

        &-content {
            font-size: 30px;
            font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
            font-weight: 800;
            color: #121212;
            line-height: 40px;
            letter-spacing: 1px;
            user-select: none;

            &_date {
                font-size: 30px;
                font-family: Impact;
                color: #FF9700;
                line-height: 40px;
            }

            .arrow {
                margin-right: -5px;
                line-height: 0;
                font-size: 0;
                border: 14px solid transparent;
                border-left-color: #FF9700;

                &.no-r {
                    border-right-width: 0;
                }
            }
        }
    }

    .p1-content {
        width: 655px;
        font-size: 16px;
        font-weight: 400;
        color: #121212;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .interaction-left {
        position: relative;
        z-index: 11;
        padding-top: 0;
    }

    .page {
        &-title {
            font-size: 30px;
            font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
            font-weight: 800;
            color: #121212;
            line-height: 40px;
            letter-spacing: 1.15px;

            &-small {
                font-size: 20px;
                font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
                font-weight: 800;
                color: #121212;
                line-height: 40px;
            }
        }

        &-summary {
            width: 632px;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 30px;
        }
    }

    .fea {
        &-img {
            width: 92px;

            &.size90 {
                width: 90px;
            }
        }
        &-title{
            font-size: 20px;
            font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
            font-weight: 800;
            color: #121212;
            line-height: 40px;
            letter-spacing: 0.77px;
            text-align: center;
        }
        &-des{
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #121212;
            line-height: 30px;
            letter-spacing: 0.4px;
        }
    }
    .soon{
        margin-bottom: 120px;
        &-title{
            margin-top: 60px;
            margin-bottom: 60px;
            font-size: 28px;
            //font-family: "MyriadPro-Bold", "MyriadPro";
            font-weight: bold;
            color: #121212;
            line-height: 40px;
            text-align: center;
        }
        &-left{
            position: relative;
            width: 520px;
            height: 215px;
            background-image: url("../img/soon/bg.png");
            background-size: cover;
        }
        &-right{
            padding: 38px 32px 50px;
            width: 520px;
            height: 215px;
            background: rgba(255, 151, 0, 0.68);
            border-radius: 8px;
            .top{
                &-icon{
                    margin-top: -4px;
                    height: 48px;
                }
                &-date{
                    font-size: 40px;
                    font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
                    font-weight: 800;
                    color: #121212;
                    line-height: 40px;
                    letter-spacing: 0.77px;
                }
            }
            .bot{
                margin-top: 30px;
                justify-content: flex-end;
                &-icon{
                    margin-right: -4px;
                    line-height: 0;
                    font-size: 0;
                    border: 27px solid transparent;
                    border-left-color: #fff;
                }
                &-date{
                    font-size: 60px;
                    font-family: Impact;
                    color: #FFFFFF;
                    line-height: 40px;
                    letter-spacing: 2px;
                }
            }
        }
        .platform-wrap{
            position: absolute;
            display: flex;
            left: 100px;
            bottom: 46px;
            img{
                max-width: 50%;
                user-select: none;
                &+img{
                    margin-left: 16px;
                }
            }
        }
    }
    .feature{
        .accordion{
            position: relative;
            height: 579px;
            min-width: 1060px;
            overflow: hidden;
            &-item{
                position: absolute;
                top: 0;
                width: 347px;
                height: 575px;
                overflow: hidden;
                text-align: center;
                border-radius: 5px;
                box-sizing: border-box;
                box-shadow: 4px 3px 4px 0 rgba(137, 116, 84, 0.71);
                transition: all 0.6s ease;
                &:nth-child(1){
                    z-index: 5;
                    img{
                        margin-top: 13px;
                        width: 100px;
                    }
                }
                &:nth-child(2){
                    left: 178px;
                    z-index: 4;
                    img{
                        margin-top: 11px;
                        width: 121px;
                    }
                }
                &:nth-child(3){
                    left: 356px;
                    z-index: 3;
                    img{
                        width: 97px;
                    }
                }
                &:nth-child(4){
                    left: 534px;
                    z-index: 2;
                    img{
                        margin-top: 13px;
                        width: 105px;
                    }
                }
                &:nth-child(5){
                    left: 712px;
                    z-index: 1;
                    .accordion-item-content{
                        background: url("../img/about/fea-bg.png");
                        background-position: center;
                        background-size: cover;
                    }
                }
                &-content{
                    padding: 30px 43px 40px;
                    width: 347px;
                    height: 575px;
                    border: 3px solid #FF9700;
                    background: linear-gradient(135deg, #FFF0D9 0%, #FFFFFF 100%);
                    transition: transform 0.6s ease;
                    &_title{
                        font-size: 20px;
                        font-family: "FranklinGothicATF-Heavy", -apple-system;
                        font-weight: 800;
                        color: #121212;
                        line-height: 40px;
                    }
                    &_des{
                        font-size: 16px;
                        font-weight: bold;
                        color: #121212;
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        text-align: left;
                    }
                    &_act{
                        position: absolute;
                        right: 43px;
                        bottom: 40px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #121212;
                        line-height: 30px;
                        text-align: right;
                        .act-icon{
                            margin-left: 4px;
                            width: 29px;
                            height: 29px;
                            letter-spacing: 0.4px;
                            background: url("../img/about/lear.png");
                            background-size: cover;
                        }
                    }
                }
            }
            &-des{
                display: block;
                position: absolute;
                left: 26px;
                top: -5px;
                height: 37px;
                font-size: 30px;
                color: #fff;
                white-space: nowrap;
                font-family: "FranklinGothicATF-Heavy";
                width: 550px;
                letter-spacing: 1.15px;
                line-height: 37px;
                transform: rotate(90deg);
                transform-origin: left;
            }
        }
    }
    #nft{
        .p1-right{
            padding: 10px 20px 8px 27px;
            width: 451px;
            height: 58px;
            letter-spacing: 1.15px;
            background: linear-gradient(180deg, #FFFAE6 0%, #FFC066 100%);
            border-radius: 10px;
            &-content{
                white-space: nowrap;
            }
            .arrow{
                margin-left: 26px;
            }
        }
        .p1-title{
            font-size: 26px;
        }
        .nft{
            &-img{
                width: 240px;
                border-radius: 10px;
            }
            &-price{
                margin-top: 38px;
                margin-bottom: 14px;
                font-family: "FranklinGothicATF-Heavy", -apple-system;
                font-size: 30px;
                font-weight: 800;
                color: #121212;
                line-height: 37px;
                letter-spacing: 1.15px;
            }
            &-des{
                font-size: 16px;
                font-weight: 400;
                color: #121212;
                line-height: 19px;
            }
            &-type{
                margin-top: 10px;
                position: relative;
                padding-top: 14px;
                font-size: 20px;
                font-family: "FranklinGothicATF-Heavy", -apple-system;
                font-weight: 800;
                color: #121212;
                line-height: 24px;
                letter-spacing: 0.77px;
                &:before{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 140px;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%,-50%);
                    border-bottom: 1px solid #121212;
                }
            }
        }
        .holder{
            padding-top: 69px;
            padding-left: 61px;
            padding-right: 73px;
            height: 309px;
            background: linear-gradient(180deg, rgba(254, 233, 201, 0.77) 0%, rgba(254, 233, 201, 0.23) 100%);
            border-radius: 10px;
            &-des{
                font-size: 20px;
                font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
                font-weight: 800;
                color: #121212;
                line-height: 40px;
                letter-spacing: 0.77px;
            }
            &-content{
                width: 667px;
                width: 667px;
                font-size: 20px;
                font-weight: 400;
                color: #121212;
                line-height: 40px;
                letter-spacing: 0.5px;
            }
        }
        .guarantee{
            padding: 44px 73px 44px 58px;
            height: 441px;
            background: linear-gradient(180deg, rgba(254, 233, 201, 0.77) 0%, rgba(254, 233, 201, 0.23) 100%);
            border-radius: 10px;
            &-content{
                width: 667px;
                font-size: 20px;
                font-weight: 400;
                color: #121212;
                line-height: 40px;
                letter-spacing: 0.5px;
            }
            &-des{
                font-size: 20px;
                font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
                font-weight: 800;
                color: #121212;
                line-height: 40px;
                letter-spacing: 0.77px;
            }
        }
    }
    .audit{
        &-left{
            min-width: 417px;
            max-width: 417px;
            img{
                max-width: 100%;
            }
        }
        &-right{
            padding-left: 110px;
            line-height: 30px;
            flex: 1;
            &-title{
                margin-top: 0;
                font-size: 20px;
                font-weight: 800;
                font-family: "FranklinGothicATF-Heavy", -apple-system;
                &.mid{
                    font-size: 17px;
                    font-weight: 600;
                }
                &.sm{
                    font-size: 15px;
                    font-weight: 600;
                }
            }
            .page-summary{
                position: relative;
                padding-bottom: 40px;
                max-width: 400px;
                p{
                    position: relative;
                    padding-left: 12px;
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: var(--d-black);
                    }
                }
                &:after{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    display: block;
                    width: 390px;
                    height: 2px;
                    background-color: #FDC08E;
                }
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    left: 14px;
                    bottom: -4px;
                    width: 9px;
                    height: 9px;
                    transform: rotate(45deg);
                    border-left: 2px solid #FDC08E;
                    border-top: 2px solid #FDC08E;
                    background: #fff;
                    z-index: 2;
                }
            }
            .p1-title-summary{
                margin-bottom: 0;
            }
        }
    }
    .team{
        .grid{
            padding-left: 58px;
        }
        &-avatar{
            max-width: 140px;
        }
        &-name{
            max-width: 140px;
            font-size: 16px;
            font-family: "FranklinGothicATF-Heavy";
            font-weight: 800;
            color: #121212;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0.4px;
        }
        &-summary{
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 32px;
            letter-spacing: 0.4px;
        }
        &-des{
            max-width: 140px;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 40px;
            white-space: nowrap;
        }
        .core{
            .team-avatar{
                margin-right: 30px;
            }
            .team-name{
                margin-top: 0;
                text-align: left;
            }
        }
        .tw-blue{
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url("../img/icon/tw-blue.svg");
            background-size: cover;
            vertical-align: text-bottom;
        }
        .icon-link{
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url("../img/icon/Linkedin.svg");
            background-size: cover;
            vertical-align: text-bottom;
        }
    }
    .featured{
        &-link{
            display: inline-block;
            margin-left: 45px;
            width: 40px;
            height: 40px;
            background: url("../img/feature/fea-link.png");
            background-size: cover;
        }
        &-item{
            display: flex;
            margin-bottom: 50px;
            align-items: center;
        }
        &-img{
            margin: 0 22px 0 56px;
            width: 150px;
            text-align: center;
        }
        &-content{
            position: relative;
            padding-left: 31px;
            width: calc(100% - 225px);
            &:after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 6px;
                width: 11px;
                height: 88px;
                background: url("../img/feature/featured-line.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        &-title{
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: bold;
            color: #121212;
            line-height: 32px;
            letter-spacing: 0.77px;
        }
        &-des{
            width: 733px;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 30px;
            letter-spacing: 0.4px;
        }
    }

    .creators {
        &-grid {
            padding-top: 70px;
            height: 658px;
            background: url("../img/creator/bg.png") center;
            background-size: cover;
        }

        &-avatar {
            width: 118px;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.06);
            }
        }

        &-nickname {
            margin-top: 12px;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: bold;
            color: #121212;
            line-height: 20px;
            letter-spacing: 0.4px;
        }

        .link-icon {
            display: inline-block;
            margin-left: 3px;
            margin-top: -2px;
            font-size: 21px;
            line-height: 1;
            vertical-align: middle;
        }
    }

    .roadmap {
        .step {
            width: 186px;

            &-arrow {
                padding: 10px;
                width: 186px;
                height: 40px;
                font-size: 20px;
                color: #F79702;
                line-height: 24px;
                font-weight: bold;
                text-align: center;
                background: url("../img/about/arrow.png");
                background-size: cover;
            }

            &-wrap {
                position: relative;
                margin-top: 60px;
                padding: 18px 25px;
                width: 186px;
                height: 368px;
                font-size: 12px;
                font-weight: 400;
                color: #121212;
                line-height: 30px;
                text-indent: 20px;
                border-radius: 8px;

                &.long {
                    min-height: 544px;
                }

                &_title {
                    margin-bottom: 16px;
                    font-size: 14px;
                    font-weight: 800;
                    color: #121212;
                    line-height: 30px;
                    text-indent: 0;
                    letter-spacing: 0.1px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    height: 10px;
                    border: 1px solid #121212;
                    border-bottom: 0;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    right: 0;
                    bottom: 0;
                    border: 1px solid;
                    border-top: 0;
                    border-image: linear-gradient(180deg, rgba(18, 18, 18, 1), rgba(255, 255, 255, 0.5)) 1 1;
                }
            }
        }
    }
    .partners{
        margin-bottom: 190px;
        &-content{
            margin-bottom: 50px;
            text-align: center;
        }
        img{
            max-height: 100px;
            &+img{
                margin-left: 100px;
            }
        }
        a+a{
            margin-left: 100px;
        }
    }
    &.dvc{
        padding-top: 88px;
        img{
            max-width: 100%;
        }
        .dvc-img{
            max-width: 100%;
            &-sm{
                display: none;
            }

        }
    }
    &.land{
        padding-top: 88px;
        .fea{
            &-des{
                font-weight: 400;
                text-align: left;
                letter-spacing: 0.4px;
            }
        }
    }
    &.dating{
        padding-top: 88px;
        .page-title-small{
            display: none;
        }
        .fea{
            &-title{
                position: relative;
                margin-top: 50px;
                padding-left: 40px;
                text-align: left;
                line-height: 20px;
                &:first-child{
                    margin-top: 0;
                }
            }
            &-des{
                width: 632px;
            }
            &-img{
                width: 320px;
            }
        }
        .img-wrap{
            margin-top: 50px;
            width: 320px;
        }

        .d-icon {
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(-50%);
        }

        .icon-a {
            width: 28px;
            height: 18px;
            background: url("../img/dataing/vr.png");
            background-size: cover;
        }
        .icon-b{
            width: 26px;
            height: 30px;
            background: url("../img/dataing/user.png");
            background-size: cover;
        }
        .icon-c{
            width: 26px;
            height: 26px;
            background: url("../img/dataing/hard.png");
            background-size: cover;
        }
        .icon-d{
            width: 20px;
            height: 30px;
            background: url("../img/dataing/eth.png");
            background-size: cover;
        }
    }
    &.space{
        padding-top: 88px;
        .col-span-6{
            img{
                width: 312px;
            }
        }
        .img-right{
            margin-left: 8px;
            width: 421px;
        }
    }
}
.toast{
    position: fixed;
    top: 50%;
    left: 50%;
    font-size: 22px;
    padding: 12px 24px;
    color: #fff;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    transform: translate(-50%,-50%);
    z-index: 9;
    transition: all 0.3s ease;
}


.img-wrap{
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    img{
        max-width: 100%;
    }
}
.user{
    position: relative;
    margin-bottom: 36px;
    width: 170px;
    font-size: 16px;
    line-height: 20px;
    &-img{
        margin-left: 6px;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        &.empty{
            background-color: var(--d-bg-1);
            font-size: 16px;
            font-weight: 800;
            line-height: 160px;
            text-align: center;
        }
    }
    &-nick{
        font-size: var(--d-fz-16);
        font-weight: 800;
        font-family: "FranklinGothicATF-Heavy", -apple-system;
    }
    &-desc{
        font-size: 14px;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-icon-tw{
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
    }
}
.interaction-wrap{
    height: 400px;
    overflow-y: auto;
}
.page-summary{
    font-family: "Myriad-Normal.otf", -apple-system;
    line-height: 20px;
    &-title{
        font-family: "FranklinGothicATF-Heavy", -apple-system;
        font-size: 20px;
        font-weight: 800;
        .icon{
            display: inline-block;
            margin-right: 15px;
            margin-left: -15px;
            width: auto;
            height: auto;
            max-width: 26px;
            vertical-align: bottom;
        }
        .help{
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }
    }
    &-content{
        margin-top: 10px;
        margin-bottom: 38px;
        padding: 12px 16px;
        background-color: #F7F7F7;
        border-radius: 8px;
        transition: all 0.3s ease;
        &:hover{
            background-color: #F9EEE3;
        }
    }
}

.pagination{
    position: fixed;
    right: unset;
    left: 50%;
    top: 45%;
    transform: translate(-635px,-50%);
    width: 5px;
    z-index: 0;
    &-bullet{
        margin: 10px 0 10px -6px;
        width: 5px;
        height: 65px;
        border-radius: unset;
        background: var(--d-bg-1);
        opacity: 0.6;
        a{
            display: block;
            width: 100%;
            height: 100%;
            &.active{
                background: var(--d-orange);
            }
        }
    }
}

.footer {
    position: relative;
    padding: 78px 0;
    background-color: #FFE2B1;
    color: var(--d-black);
    font-size: var(--d-fz-24);
    z-index: 1;

    &-connect {
        min-width: 310px;
        margin-right: 65px;
    }
    &-content{
        margin-left: 65px;
        min-width: 310px;
        font-size: 20px;
        font-weight: 400;
        color: #121212;
        line-height: 30px;
        letter-spacing: 0.77px;
    }

    .cr {
        margin-top: 14px;
        font-size: 16px;
        line-height: 20px;
    }

    &-summary {
        margin: 20px auto 0;
        max-width: 730px;
        font-size: 12px;
        line-height: 20px;
    }
}

.account {
    position: fixed;
    z-index: 9;
    right: 10px;
    bottom: 10px;
    display: none;
}

// nft section
.guide{
    .v{
        position: relative;
        margin: 0 auto 40px;
        width: 100%;
        height: auto;
        font-size: 0;
        line-height: 0;
        &-video{
            width: 100%;
            height: auto;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            -webkit-tap-highlight-color: transparent;
        }
        &-mask{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    &-content{
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        letter-spacing: 0.27px;
        line-height: 40px;
    }
    &-wrap{
        position: relative;
        margin-left: -88px;
        min-height: 695px;
        &-mask{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .volume-icon{
            position: absolute;
            right: 0;
            bottom: 50px;
            width: 60px;
            height: 60px;
            background: url("../img/mint/close.svg");
            transform: translate(-50%,-50%);
            background-size: cover;
            z-index: 2;
            cursor: pointer;
            //transition: background 0.3s ease;
            &.open{
                background: url("../img/mint/open.svg");
                background-size: cover;
            }
        }
        .play-icon{
            position: absolute;
            margin-top: -25px;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            background: url("../img/mint/play.svg");
            transform: translate(-50%,-50%);
            background-size: cover;
            z-index: 3;
            cursor: pointer;
            transition: all 0.2s ease;
            opacity: 0;
            &.play{
                opacity: 1;
            }
        }
    }
    .guide-btn{
        display: block;
        position: relative;
        margin: 0 auto;
        width: 224px;
        font-size: 48px;
        font-weight: 800;
        color: #FF9700;
        font-family: "FranklinGothicATF-Heavy", -apple-system;
        text-align: center;
        cursor: pointer;
        border-radius: 22px;
        border: 4px solid #FF9700;
        line-height: 72px;
        box-sizing: border-box;
        overflow: hidden;
        opacity: 0.8;
        transition: all ease 0.3s;
        &:hover{
            opacity: 1;
        }
    }
    @keyframes rotate {
        100% {
            transform: translate(-50%, -50%) rotate(1turn);
        }
    }
}
.mint{
    &>.container{
        padding-left: 80px;
        padding-top: 180px;
    }
    &-wrap{
        position: relative;
        &-mask{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .volume-icon{
            position: absolute;
            right: 0;
            bottom: 50px;
            width: 60px;
            height: 60px;
            background: url("../img/mint/close.svg");
            transform: translate(-50%,-50%);
            background-size: cover;
            z-index: 2;
            cursor: pointer;
            //transition: background 0.3s ease;
            &.open{
                background: url("../img/mint/open.svg");
                background-size: cover;
            }
        }
        .play-icon{
            position: absolute;
            margin-top: -25px;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            background: url("../img/mint/play.svg");
            transform: translate(-50%,-50%);
            background-size: cover;
            z-index: 3;
            cursor: pointer;
            transition: all 0.2s ease;
            opacity: 0;
            &.play{
                opacity: 1;
            }
        }
    }
    &-video{
        max-width: 100%;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    &-top{
        display: flex;
        justify-content: space-between;
        &-left{
            min-width: 560px;
            height: 560px;
            border-radius: 13px;
            background-size: cover;
        }
        &-right{
            flex: 1;
            padding-left: 53px;
            &>p{
                margin-bottom: 14px;
                font-size: 21px;
                line-height: 1;
                font-family: "FranklinGothicATF-Heavy";
                text-transform: uppercase;
                &:nth-child(1){
                    color: #979797;
                }
                &:nth-child(2){
                    color: #FF9700;
                }
                &:nth-child(3){
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 46px;
                    color: #FF9700;
                }
            }
            .validate{
                font-size: 16px;
                color: rgb(64,158,255);
                font-weight: bold;
                font-family: '"FranklinGothicATF-Heavy"';
            }
        }
        &-main{
            padding: 26px;
            width: 510px;
            //height: 425px;
            text-align: center;
            border-radius: 19px;
            border: 2px solid #FF9700;
            &_title{
                font-size: 20px;
                font-family: "FranklinGothicATF-Heavy";
                font-weight: 800;
                line-height: 39px;
            }
            &_des{
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
            }
            &_checkbox{
                margin: 0 auto;
                width: 60px;
                color: #BCBCBC;
                font-size: 26px;
                font-family: "FranklinGothicATF-Heavy", -apple-system;
                line-height: 54px;
                font-weight: 800;
                border-radius: 12px;
                border: 4px solid #BCBCBC;
                transition: all 0.3s ease;
                user-select: none;
                cursor: pointer;
                -webkit-tap-highlight-color:rgba(0,0,0,0);
                -webkit-tap-highlight-color: transparent;
                &.active{
                    color: #121212;
                    border-color: #FF9700;
                }
            }
        }
        &-btn{
            display: inline-block;
            margin: 0 auto;
            width: 203px;
            height: 58px;
            font-size: 24px;
            font-family: "FranklinGothicATF-Heavy";
            font-weight: 800;
            color: #FFFFFF;
            line-height: 58px;
            text-align: center;
            background: #FF9700;
            border-radius: 49px;
            cursor: pointer;
            user-select: none;
            border: 0;
            &.sm{
                min-width: 264px;
                font-size: 18px;
                white-space: nowrap;
            }
        }
    }
    &-bottom{
        padding: 0 22px;
        gap: 70px;
        font-size: 20px;
        line-height: 40px;
        .page-summary-title{
            margin-bottom: 16px;
        }
        .icon{
            margin: 0 auto;
            width: 120px;
            height: 120px;
            font-size: 0;
            line-height: 0;
            background-size: cover;
            &-fee{
                background: url("../img/mint/free.svg");
            }
            &-price{
                background: url("../img/mint/price.svg");
            }
        }
        .fee{
            padding-top: 48px;
            min-height: 380px;
            font-size: 20px;
            background: linear-gradient(180deg, rgba(254, 233, 201, 0.77) 0%,rgba(254, 233, 201, 0.15) 100%);
            border-radius: 10px;
        }
        .price{
            padding-top: 48px;
            min-height: 380px;
            font-size: 16px;
            background: linear-gradient(180deg, rgba(254, 233, 201, 0.77) 0%,rgba(254, 233, 201, 0.15) 100%);
            border-radius: 10px;
        }
    }
}
.countdown-mint{
    margin-top: 24px;
    font-size: 24px;
    position: relative;
    padding-bottom: 4px;
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 2%;
        bottom: 0;
        width: 96%;
        height: 1px;
        background: #121212;
    }
}

// phone section
.m-navbar {
    display: none;
    height: 66px;

    img {
        width: 27px;
        height: 27px;
        &.logo2{
            margin-left: 11px;
            width: auto;
            height: 27px;
        }
    }

    line-height: 20px;

    .m-close, .m-open {
        display: none;
        width: 21px;
        height: 21px;
        background-image: url("../img/m-close.png");
        background-size: cover;
    }

    .m-open {
        display: block;
        background-image: url("../img/m-menu.png");
    }

    &-nav {
        display: none;
        text-align: left;
        color: #121212;
        font-size: 12px;
        font-weight: 800;
        font-family: "Franklin Gothic ATF";
        font-family: "FranklinGothicATF-Heavy", -apple-system;

        li {
            display: block;
            margin: 12.5px 0;
            font-size: 15px;
            line-height: 20px;

            & + li {
                margin-left: 0;
            }

            &.flex {
                display: flex;
            }

            ul {
                margin-left: 24px;
            }
        }

        a.page-scroll {
            padding: 0;
            display: block;
        }

        .btn-connect {
            margin: 22px 0;
            font-size: 15px;
            font-family: FranklinGothicATF-Heavy, FranklinGothicATF;
            font-weight: 800;
            color: #121212;
            line-height: 40px;
            text-align: center;
            border-radius: 40px;
            border: 1px solid #FF9700;
        }
    }
}

.m-container {
    display: none;
}

.m-footer {
    display: none;
    font-size: 12px;
}

.road {
    margin-bottom: 80px;

    &-content {
        position: relative;
        display: flex;
        padding-top: 30px;
        font-size: 14px;
        line-height: 20px;
        justify-content: space-evenly;
        border-top: 2px solid rgba(18, 18, 18, 0.6);

        &:after {
            content: '';
            position: absolute;
            right: -8px;
            top: -1px;
            width: 0px;
            height: 0px;
            border: 8px solid transparent;
            border-left-color: var(--d-black);
            transform: translateY(-50%);
        }

        li {
            margin-left: 1.5em;
        }

        .step-wrap {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                display: block;
                top: -30px;
                left: 50%;
                width: 16px;
                height: 16px;
                background-color: var(--d-bg-1);
                border: 1px solid var(--d-black);
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .default-title {
        margin-bottom: 65px;
    }

    .btn-step {
        width: 125px;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        font-weight: bold;
        border: 1px solid var(--d-black);
        border-radius: 8px;
    }

    .sec-title {
        margin: 10px 0;
        font-size: 14px;
        color: var(--d-black);
        font-weight: 800;
        line-height: 30px;
        font-family: "FranklinGothicATF-Heavy", -apple-system;
    }

    li {
        color: #979797;
    }
}

$dev: 640px;

@media screen and (max-width: $dev) {
    #root {
        min-width: unset;
    }
    .cta-wallet {
        display: none;
    }
    .nav {
        right: 0;
        height: 66px;
        overflow: hidden;
        padding: 0 22px;
        min-width: 300px;
        width: 100vw;
        line-height: 1;
        background-color: rgba(247, 247, 247, 0.95);
        //background-color: transparent;
        box-sizing: border-box;
        transition: all 0.3s ease;
        .navbar-nav {
            display: none;
            width: 100%;
            padding-right: 0;
        }
    }
    .m-navbar {
        display: flex;
    }
    .m-navbar-nav {
        display: block;
    }
    .m-cr {
        font-size: 12px;
        font-weight: 400;
        font-family: "Myriad-Normal.otf", -apple-system;
    }
    .default-title {
        margin-bottom: 15px;
        font-size: 30px;
    }
    .m-footer {
        display: block;
        padding: 20px;
        font-size: 12px;
        color: #121212;
        background-color: rgba(247, 247, 247, 0.95);
        text-align: center;
    }

    .default-title {
        font-family: "FranklinGothicATF-Heavy", -apple-system;
        position: relative;
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 1;

        span {
            display: none;
            -webkit-text-stroke: 1px #000;
            color: transparent;
            position: absolute;
            top: -4px;
            left: 4px;
            z-index: 1;
        }
    }

    .main-wrap {
        padding-top: 0;
        .container {
            padding: 77px 23px 0;
            min-width: 300px;

            &.feature{
                padding-top: 0;
            }
            &.road{
                padding-left: 0;
                padding-right: 0;
            }
        }
        .p1{
            &-title{
                font-size: 30px;
                line-height: 1;
                letter-spacing: 0;
                &-summary{
                    margin-top: 15px;
                    margin-bottom: 25px;
                    font-size: 15px;
                    line-height: 20px;
                }
            }
            &-right{
                margin-top: 15px;
                zoom: 0.5;
            }
            &-content{
                width: auto;
                font-size: 13px;
                line-height: 20px;
            }
            &-right{
                &-icon{
                    padding-right: 18px;
                }
                &-content{
                    letter-spacing: 0;
                    &_date{
                        font-size: 38px;
                        font-family: "FranklinGothicATF-Heavy";
                        font-weight: 800;
                    }
                }
            }
        }


        .page {
            &-title {
                margin-top: 35px;
                margin-bottom: 35px;
                font-size: 30px;
                line-height: 20px;
            }

            &-summary {
                width: auto;
                font-size: 13px;
                letter-spacing: 1px;
                line-height: 1.5;
            }
        }

        .fea {
            &-title {
                font-size: 15px;
                line-height: 1.5;
                letter-spacing: 0.4px;
            }

            &-des {
                font-size: 10px;
                line-height: 18px;
                letter-spacing: 1px;
            }
        }
        .roadmap{
            .step-wrap{
                height: auto;
                width: auto;
            }
        }
        .feature{
            &.container{
                padding-right: 0;
            }
            .scroll-wrap{
                max-width: 100%;
                overflow: auto;
            }
            .accordion{
                display: flex;
                zoom: 0.5;
                min-width: 2226px;
                overflow: visible;
                &-item{
                    position: relative;
                    left: auto!important;
                    width: 420px;
                    height: 545px;
                    box-shadow: none;
                    overflow: revert;
                    transition: none;
                    &-content{
                        width: 420px;
                        height: 545px;
                        border-radius: 5px;
                        &_title{
                            margin: 20px auto 28px;
                            font-size: 26px;
                            line-height: 40px;
                        }
                        &_des{
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: 500;
                        }
                        &_act{
                            font-size: 16px;
                        }
                    }
                    &+.accordion-item{
                        margin-left: 20px;
                    }
                }
                &-des{
                    font-family: inherit;
                    top: -20px;
                }
            }
        }
        .team,
        .featured,
        .creators{
            .p1-title-summary{
                font-size: 30px;
                letter-spacing: 0;
            }
            .mid-title{
                font-size: 15px;
                letter-spacing: 0;
            }
        }
        &.dvc,
        &.space,
        &.land,
        &.dating{
            padding-top: 0;
        }
    }
    #datingverse{
        &>.flex{
            display: block;
        }
        .gap-4{
            gap: 8px;
        }
        .col-span-3{
            grid-column: span 6/span 6;
        }
        .p1-content{
            margin-bottom: 0;
        }
    }
    #nft{
        &.container{
            .p1-right{
                margin-top: 0;
                width: auto;
                letter-spacing: 0.4px;
            }
            .nft{
                text-align: center;
                &-img{
                    max-width: 100%;
                }
                &-price{
                    margin-top: 24px;
                    margin-bottom: 0;
                    font-size: 20px;
                    line-height: 25px;
                }
                &-des{
                    font-size: 8px;
                    line-height: 10px;
                }
                &-type{
                    margin-top: 5px;
                    padding-top: 6px;
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    &:before{
                        width: 70px;
                    }
                }
            }
            .holder,
            .guarantee{
                padding: 19px 15px;
                height: auto;
                &>.flex{
                    display: block;
                }
                img{
                    zoom: 0.5;
                }
                &-content{
                    font-size: 13px;
                    width: auto;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
                &-des{
                    margin: 10px auto 15px;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
        .audit{

            img{
                max-width: 100%;
            }
        }
    }
    #team{
        &.container{
            .grid{
                padding-left: 0;
                img{
                    zoom: 0.5;
                }
            }
        }
        .team{
            &-name{
                margin-top: 10px;
                font-size: 13px;
                line-height: 20px;
            }
            &-des{
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0;
                white-space: break-spaces;
            }
            &-summary{
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.25px;
            }
        }
        .core{
            .flex{
                display: block;
                text-align: center;
            }
            .team{
                &-avatar{
                    margin-right: 0;
                }
                &-name{
                    margin-bottom: 2px;
                    width: auto;
                    max-width: 100%;
                    text-align: center;
                }
                &-des{
                    max-width: 100%;
                    .icon-link,
                    .tw-blue{
                        width: 12px;
                        height: 12px;
                        vertical-align: text-top;
                    }
                }
            }
            &:first-child{
                .team-summary{
                    padding-right: 17px;
                }
            }
            &:nth-child(2){
                .team-summary{
                    padding-left: 17px;
                }
            }

        }
    }
    #featured{
        .featured{
            &-item{
                margin-bottom: 25px;
                zoom: 0.5;
            }
            &-link{
                margin-left: 15px;
                width: 25px;
                height: 26px;
            }
            &-img{
                margin: 0;
            }
            &-content{
                width: calc(100% - 135px);
                letter-spacing: 0.5px;
                &:after{
                    height: 84%;
                    background-position: top;
                }
            }
            &-title{
                font-size: 26px;
                line-height: 32px;
                letter-spacing: 0.5px;
                font-weight: 500;
            }
            &-des{
                width: auto;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    #creators{
        .creators-grid{
            padding-top: 0;
            height: auto;
            background: none;
            .col-span-3{
                grid-column: span 4/span 4;
            }
            .creators{
                &-avatar{
                    width: 80px;
                }
                &-nickname{
                    margin: 6px auto 15px ;
                    font-size: 10px;
                    line-height: 1;
                    font-weight: 800;
                    font-family: "FranklinGothicATF-Heavy";
                    .link-icon{
                        font-size: 24px;
                        zoom: 0.5;
                    }
                }
            }
        }
    }
    .road{
        .default-title{
            margin-bottom: 36px;
            padding-left: 23px;
            text-align: left;
        }
        &-content{
            position: relative;
            padding: 17px 0;
            display: grid;
            grid-template-columns: repeat(12,minmax(0,1fr));
            //gap: 28px;
            border: unset;
            &:before{
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: 0;
                width: 2px;
                height: 100%;
                background: #121212;
                transform: translateX(-1px);
            }
            &:after{
                top: 100%;
                right: 50%;
                border: 8px solid transparent;
                border-top-color: #121212;
                transform: translate(8px,-6px);
            }
            li{
                margin-left: 11px;
                font-size: 12px;
                zoom: 0.85;
                line-height: 20px;
            }
        }
        .sec-title{
            font-size: 13px;
            line-height: 15px;
            &+.sec-title{
                margin-top: 15px;
            }
        }
        li{
            color: #121212;
        }
        .col-span-6{
            &:first-child{
                margin-top: 75px;
                .step-wrap{
                    margin-right: 16px;
                    padding-left: 0;
                    border: 1px solid #121212;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-left-color: transparent;
                }
            }
            &:last-child{
                .step-wrap{
                    margin-left: 16px;
                    padding-right: 0;
                    border: 1px solid #121212;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-right-color: transparent;
                }
            }
        }
        .text-right{
            text-align: right;
        }
        .step-wrap{
            margin-bottom: 25px;
            padding: 16px;
            line-height: 20px;
            &:before{
                width: 10px;
                height: 10px;
                top: 15px;
                left: -17px;
                background-color: #FF9700;
                border: 0.5px solid #121212;
                transform: translateX(-50%);
            }
            &.step-wrap_left{
                &:before{
                    top: 15px;
                    left: unset;
                    right: -16px;
                    transform: translateX(50%);
                }
            }
        }
        .btn-step{
            width: auto;
            font-size: 15px;
            font-weight: 800;
            color: #FF9700;
            text-align: inherit;
            line-height: inherit;
            border: unset;
            border-radius: 8px;
            font-family: "FranklinGothicATF-Heavy", -apple-system;
        }
        &-end{
            margin-bottom: 60px;
            text-align: center;
            font-size: 30px;
            font-weight: 800;
            font-family: "FranklinGothicATF-Heavy", -apple-system;
        }
    }

    .ab-p5{
        img{
            display: block;
            max-width: 168px;
            margin-top: 50px;
            &.s{
                max-width: 60px;
            }
        }
        .flex-item{
            //text-align: -webkit-center;
        }
        .img-left{
            margin-right: 50px;
            &:first-child{
                margin-left: 30px;
            }
            img{
                max-width: 60px;
            }
        }
    }

    .guide{
        &-content{
            font-size: 12px;
            line-height: 2;
            .page-title{
                line-height: 1.1;
            }
        }
        &-wrap{
            margin-left: 0;
            min-height: auto;
        }
        &-btn{
            zoom: 0.5;
        }

        .volume-icon{
            width: 32px;
            height: 32px;
            right: 0;
            bottom: 16px;
            transform: translate(-6px,-50%);
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            -webkit-tap-highlight-color: transparent;
        }
        .play-icon{
            margin-top: -12px;
            width: 50px;
            height: 50px;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            -webkit-tap-highlight-color: transparent;
        }
    }
    .mint{
        &-top{
            display: block;
            margin-bottom: 25px;
            &-left{
                margin-bottom: 25px;
                margin-top: 25px;
                min-width: 100%;
                width: 100%;
                height: calc(100vw - 46px);
            }
            &-right{
                padding-left: 0;
                &>p{
                    margin-bottom: 5px;
                    zoom: 0.74;
                    .validate{
                        font-size: 18px;
                    }
                }
            }
            &-main{
                margin-top: 20px;
                padding: 16px;
                width: 100%;
                height: auto;
                &_title{
                    font-size: 15px;
                    line-height: 20px;
                }
                &_checkbox{
                    display: inline-block;
                    text-align: center;
                    zoom: 0.5;
                }
                &_des{
                    font-size: 13px;
                    line-height: 20px;
                }
                .sm-bold{
                    font-family: "FranklinGothicATF-Heavy", -apple-system;
                    //font-size: 13px;
                    font-weight: 800;
                }
            }
            &-btn{
                min-width: 115px;
                width: auto;
                height: auto;
                font-size: 15px;
                line-height: 30px;
                border-radius: 25px;
                -webkit-tap-highlight-color:rgba(0,0,0,0);
                -webkit-tap-highlight-color: transparent;
                &.sm{
                    min-width: auto;
                    padding: 0 20px;
                    font-size: 13px;
                }
            }
        }
        &-bottom{
            display: block;
            padding: 0;
            line-height: 20px;
            .fee,
            .price{
                display: flex;
                padding: 15px 20px 15px 10px;
                width: 100%;
                min-height: 90px;
                align-items: center;
            }
            .price{
                margin-top: 25px;
            }
            .icon{
                width: 60px;
                height: 60px;
                background-size: contain;
            }
            .sm-wrap{
                margin-left: 14px;
                max-width: 226px;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
            }
            .page-summary-title{
                margin-top: 0;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 800;
                line-height: 1;
            }
        }
        #option{
            gap: 11px;
            .col-span-6{
                font-size: 0;
                text-align: right;
                &:last-child{
                    text-align: left;
                }
            }
        }
        .page-title{
            line-height: 1.2;
            zoom: 0.7;
        }
        .toast{
            font-family: "FranklinGothicATF-Heavy", -apple-system;
            font-size: 18px;
            letter-spacing: 1px;
        }
        .countdown-mint{
            margin-top: 0;
            font-size: 18px;
            line-height: 1.5;
            &:after{
                display: none;
            }
            .sm-show{
                display: revert;
            }
        }
    }
    .land{
        .gap-30{
            gap: 15px;
        }
        .col-span-3{
            grid-column: span 6/span 6;
            .fea{
                &-img{
                    &.size90{
                        width: 45px;
                    }
                }
                &-title{
                    font-size: 13px;
                }
                &-des{
                    margin-top: 10px;
                    font-size: 10px;
                    margin-bottom: 15px;
                    line-height: 1.5;
                }
            }
            &:nth-child(3){
                margin-top: -50px;
            }
            &:nth-child(4){
                margin-top: -10px;
            }
            &:nth-child(5){
                margin-top: -80px;
            }
            &:nth-child(6){
                margin-top: -10px;
            }
        }

        .soon {
            display: none;
        }
    }
    .space {
        .soon {
            display: none;
        }

        .img-right {
            display: none;
        }

        .col-span-6 {
            grid-column: span 12/span 12;
            width: 100%;
            //object-fit: fill;
        }

        .gap-8 {
            gap: 10px;
        }
    }
    .dating{
        .soon{
            display: none;
        }
        .flex.flex-btw.mb30{
            display: block;
        }
        .flex{
            .mb30{
                margin-bottom: 10px;
            }
            .d-icon{
                top: 50%;
            }
            .icon{
                &-a{
                    width: 18px;
                    height: 12px;
                }
                &-b{
                    width: 17px;
                    height: 20px;
                }
                &-c{
                    width: 20px;
                    height: 20px;
                }
                &-d{
                    margin-left: 4px;
                    width: 12px;
                    height: 20px;
                }
            }
            .fea{
                &-title{
                    padding-left: 30px;
                    margin-top: 25px;
                }
                &-des{
                    margin-top: 10px;
                    margin-bottom: 25px;
                    padding: 14px 16px;
                    width: auto!important;
                    font-size: 13px;
                    border: 1px solid #121212;
                    border-radius: 4px;
                }
            }

        }
        &.main-wrap{
            .page-title{
                margin-bottom: 15px;
            }
            .page-title-small{
                display: block;
                margin-bottom: 35px;
                font-size: 15px;
                line-height: 20px;
            }
            .img-wrap{
                margin-top: 25px;
                width: 100%;
            }
            .fea-img{
                width: 100%;
            }
        }

    }
    .dvc{
        margin-bottom: 50px;
        .soon,.soon-title{
            display: none;
        }
        .grid{
            margin-top: 35px;
        }
        .col-span-3{
            grid-column: span 4/span 4;
            line-height: 0;
        }
        .fea{
            &-img{
                width: 46px;
            }
            &-des{
                margin-top: 10px;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 25px;
                letter-spacing: 0.5px;
                zoom: 0.5;
            }
        }
        .page-title{
            &-small{
                font-size: 13px;
                line-height: 20px;
            }
            &.mid-title{
                margin-bottom: 15px;
                font-size: 15px;
                line-height: 20px;
            }
        }
        .mb30{
            margin-bottom: 10px;
        }
        &-img{
            display: none;
            &-sm{
                display: block!important;
                max-width: 100%;
            }
        }

    }
    .mint{

    }

    .footer{
        padding: 40px 0;
        font-size: 12px;
        text-align: center;
        img{
            width: 166px;
            height: auto!important;
        }
        &-connect{
            margin: 0;
            margin-bottom: 20px;
        }
        &-content{
            margin: 0;
            font-size: 10px;
            line-height: 1.5;
        }
        .cr{
            font-size: 8px;
        }
        .container{
            padding: 10px;
            &>.flex{
                display: block;
            }
        }
        .mb30{
            margin-bottom: 10px;
        }
        .iconfont {
            font-size: 16px;
            &+.iconfont{
                margin-left: 8px;
            }
        }
    }

    .msg{
        &-content{
            min-width: 50%;
            padding: 16px 20px;
        }
        &-icon{
            width: 80px;
            height: 80px;
        }
        &-title{
            margin-top: 10px;
            font-size: 20px;
            line-height: 24px;
        }
        &-summary{
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
        }
        &-close{
            zoom: 0.6;
        }
    }
}
